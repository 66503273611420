import React from "react";
import { PageProps } from "gatsby";
import DefaultLayout from "../layouts/DefaultLayout";

const Page: React.FC<PageProps> = ({ location }) => (
  <DefaultLayout pathname={location.pathname} pageTitle="About Us">
    <div className="container">
      <p className="text-size-medium">
        Wood ‘N’ Doors is one of South Australia’s largest manufacturers of
        timber products. We have over 20 years experience working with timber.
        All of our items are custom made to our clients unique specifications.
      </p>

      <div className="card my-3">
        <div className="card-body">
          <h2 className="card-title">Our Guarantee</h2>
          <p className="card-text">
            Wood ‘N’ Doors pty ltd warrants all products to the original
            purchaser for a period of 5 years. The warranty covers warps or
            splits due to manufacturing faults. Subject to inspection, if your
            product is faulty, we will repair or replace the item with a
            comparable product from our range.
          </p>

          <p className="card-text">
            Warranties do not cover refitting or recoating products.
          </p>
        </div>
      </div>
    </div>
  </DefaultLayout>
);

export default Page;
